import React from 'react';
import Container from '@/components/Container';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { Tween, ScrollTrigger } from 'react-gsap';
// @ts-ignore
import * as Styles from './style.module.scss';
import 'swiper/css';
// @ts-ignore
import Image1 from '../../images/1.jpg';
// @ts-ignore
import Image2 from '../../images/2.jpg';
// @ts-ignore
import Image3 from '../../images/3.jpg';
// @ts-ignore
import Image4 from '../../images/4.jpg';
// @ts-ignore
import Image5 from '../../images/5.jpg';
// @ts-ignore
import VinuSwapMockup from '../../images/vinuswap-mockup.png';

const Products: React.FunctionComponent = () => {
  return (
    <div className={Styles.productWrap}>
      <ScrollTrigger start="top 65%">
        <section className={Styles.vinuSwapSection}>
          <Container>
            <div className={Styles.headingWrap}>
              <div className={Styles.col}>
                <Tween
                  to={{
                    autoAlpha: 1,
                    duration: 0.9,
                    ease: `power2`,
                    delay: 0.1,
                    stagger: 0.2,
                    y: 0,
                  }}
                >
                  <svg
                    width="89"
                    height="30"
                    viewBox="0 0 89 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 15.0004C0 6.72951 6.72845 0.000352011 15 0.000352011C16.9784 -0.0132017 18.94 0.364769 20.7717 1.1125C22.6035 1.86023 24.2692 2.96294 25.673 4.35713C27.0768 5.75133 28.1909 7.40945 28.9511 9.23604C29.7114 11.0626 30.1028 13.0215 30.1028 15C30.1028 16.9785 29.7114 18.9374 28.9511 20.764C28.1909 22.5906 27.0768 24.2487 25.673 25.6429C24.2692 27.0371 22.6035 28.1398 20.7717 28.8875C18.94 29.6353 16.9784 30.0132 15 29.9996C6.72845 29.9996 0 23.2712 0 15.0004ZM25.363 15.0004C25.3652 13.1132 24.8513 11.2614 23.8769 9.64528C22.9025 8.02916 21.5048 6.71027 19.8348 5.83126C18.3463 5.04028 16.6856 4.62919 15 4.63454C9.28524 4.63454 4.6363 9.28067 4.6363 14.9983C4.6363 20.7158 9.28242 25.3613 15 25.3613C16.6855 25.3664 18.3463 24.9553 19.8348 24.1646C21.504 23.2859 22.9013 21.9678 23.8756 20.3526C24.85 18.7375 25.3643 16.8866 25.363 15.0004Z"
                      fill="black"
                    />
                    <path
                      d="M15.9602 3.78275L19.832 1.73282C21.9914 0.594864 24.3956 0.000230925 26.8364 0.000366234C35.108 0.000366234 41.8372 6.72951 41.8372 15.0004C41.8372 23.2712 35.108 29.9997 26.8364 29.9997C24.3963 29.9996 21.9928 29.4049 19.8341 28.2672L15.9623 26.218L19.8341 24.1695C21.5027 23.2888 22.8993 21.9695 23.8734 20.3538C24.8476 18.738 25.3624 16.8871 25.3624 15.0004C25.3624 13.1137 24.8476 11.2627 23.8734 9.64699C22.8993 8.03124 21.5027 6.71197 19.8341 5.83127L15.9602 3.78275ZM29.9993 15.0004C30.0037 18.8399 28.533 22.5344 25.891 25.3204C26.205 25.3486 26.5201 25.3629 26.8364 25.3634C32.5512 25.3634 37.2009 20.7172 37.2009 15.0004C37.2009 9.28348 32.5512 4.63666 26.8364 4.63666C26.5201 4.63666 26.2052 4.6512 25.8917 4.68028C28.5338 7.46622 30.0043 11.1609 29.9993 15.0004Z"
                      fill="black"
                    />
                    <path
                      d="M48.0641 2.18831C48.4056 2.1873 48.7379 2.29634 49.0121 2.49924C49.2867 2.70215 49.4879 2.9881 49.5872 3.31465L51.623 10.0375C51.8109 10.6358 51.9304 11.1638 52.0162 11.5595C52.067 11.7819 52.1016 12.0043 52.1529 12.193H52.2235C52.2747 12.0043 52.3093 11.7819 52.3605 11.5595C52.4459 11.1667 52.5655 10.6358 52.7533 10.0375L54.8067 3.24566C54.9105 2.90297 55.1339 2.60888 55.4362 2.41692C55.7381 2.22497 56.0995 2.1479 56.4535 2.19974C56.8079 2.25158 57.1319 2.42889 57.3664 2.69939C57.6009 2.9699 57.7305 3.31563 57.7319 3.67367C57.7315 3.83703 57.7024 3.99904 57.6461 4.15236L54.4781 13.6467C54.3105 14.153 53.987 14.5931 53.5532 14.9035C53.1198 15.214 52.5987 15.3788 52.0656 15.3742C51.5367 15.3698 51.0225 15.2012 50.5938 14.8918C50.165 14.5823 49.8429 14.1472 49.6721 13.6467L46.5415 4.29456C46.4884 4.12857 46.4598 3.95568 46.4565 3.78138C46.4589 3.35706 46.6296 2.95102 46.931 2.65236C47.2324 2.3537 47.6399 2.18681 48.0641 2.18831Z"
                      fill="black"
                    />
                    <path
                      d="M59.0948 3.83058C59.0902 3.61209 59.1295 3.39489 59.2098 3.19171C59.2901 2.98854 59.4105 2.80347 59.5633 2.64735C59.7161 2.49123 59.8988 2.3672 60.1005 2.28253C60.3018 2.19785 60.5182 2.15424 60.7365 2.15424C60.9553 2.15424 61.1713 2.19785 61.373 2.28253C61.5742 2.3672 61.757 2.49123 61.9098 2.64735C62.063 2.80347 62.183 2.98854 62.2638 3.19171C62.3441 3.39489 62.3833 3.61209 62.3787 3.83058V13.7353C62.3699 14.1649 62.1927 14.5739 61.8858 14.8746C61.5788 15.1752 61.1662 15.3437 60.7365 15.3437C60.3068 15.3437 59.8942 15.1752 59.5873 14.8746C59.2804 14.5739 59.1036 14.1649 59.0948 13.7353V3.83058Z"
                      fill="black"
                    />
                    <path
                      d="M64.5618 4.41208C64.5604 3.92618 64.7187 3.4533 65.0122 3.06604C65.3053 2.67879 65.7179 2.39857 66.1859 2.26842C66.6544 2.13828 67.1524 2.16539 67.6033 2.34562C68.0547 2.52583 68.4341 2.84919 68.6838 3.26603L72.1061 8.94489C72.4679 9.54042 72.5986 9.8706 72.8418 10.4162H72.9304V3.67644C72.9387 3.28504 73.1002 2.91253 73.3799 2.63872C73.6596 2.36491 74.0358 2.21157 74.4272 2.21157C74.8186 2.21157 75.1947 2.36491 75.4744 2.63872C75.7541 2.91253 75.9156 3.28504 75.9244 3.67644V13.2904C75.9226 13.7468 75.7716 14.1901 75.4947 14.5529C75.2182 14.9156 74.8301 15.178 74.3902 15.3001C73.9509 15.4221 73.4833 15.3971 73.0592 15.2289C72.6345 15.0606 72.2769 14.7584 72.0405 14.3682L68.4502 8.44929C68.0981 7.87348 67.8807 7.47642 67.6809 6.94352H67.595V13.8719C67.583 14.2653 67.4182 14.6386 67.1358 14.9127C66.8533 15.1867 66.4749 15.34 66.0816 15.34C65.6879 15.34 65.3095 15.1867 65.027 14.9127C64.7445 14.6386 64.5798 14.2653 64.5678 13.8719L64.5618 4.41208Z"
                      fill="black"
                    />
                    <path
                      d="M78.0601 3.83057C78.0601 3.39275 78.2341 2.97287 78.5437 2.66328C78.8534 2.3537 79.273 2.17978 79.711 2.17978C80.149 2.17978 80.5685 2.3537 80.8782 2.66328C81.1879 2.97287 81.3619 3.39275 81.3619 3.83057V10.4337C81.3619 11.8705 81.961 12.811 83.4661 12.811C84.955 12.811 85.5531 11.8191 85.5531 10.4337V3.81297C85.5619 3.3856 85.7382 2.97873 86.0433 2.67962C86.3488 2.38051 86.7591 2.21299 87.1865 2.21299C87.6144 2.21299 88.0247 2.38051 88.3302 2.67962C88.6353 2.97873 88.8116 3.3856 88.8204 3.81297V10.331C88.8204 13.3073 87.4348 15.5311 83.4836 15.5311C79.3948 15.5311 78.0628 13.3587 78.0628 10.331L78.0601 3.83057Z"
                      fill="black"
                    />
                    <path
                      d="M50.6326 17.5346C52.2069 17.5346 54.6004 18.195 54.6004 19.7226C54.6004 20.3956 54.0535 20.8862 53.3815 20.8862C52.1995 20.8862 52.1916 19.6099 50.5656 19.6099C49.6504 19.6099 49.121 20.0323 49.121 20.5216C49.121 21.0404 49.3878 21.4191 50.453 21.6437L52.1635 22.0083C54.3082 22.4708 55.1639 23.5641 55.1639 25.1487C55.1639 26.9438 54.0281 28.6122 50.7332 28.6122C48.6212 28.6122 46.2881 28.0117 46.2881 26.3827C46.2881 25.6788 46.8627 25.2191 47.5638 25.2191C48.7527 25.2191 48.9378 26.3405 50.621 26.3405C51.7426 26.3405 52.3458 26.004 52.3458 25.331C52.3458 24.8383 52.079 24.5039 51.0696 24.2934L49.3449 23.9287C47.3764 23.5085 46.4122 22.5271 46.4122 20.7883C46.4122 18.951 47.9395 17.5346 50.6326 17.5346Z"
                      fill="black"
                    />
                    <path
                      d="M56.9871 17.6754C57.2853 17.6733 57.5747 17.7739 57.8073 17.9603C58.0394 18.1468 58.201 18.4076 58.2633 18.6989L59.6797 25.3866L61.3902 18.7693C61.471 18.4574 61.6533 18.1811 61.9081 17.9839C62.1628 17.7867 62.4762 17.6796 62.7984 17.6796C63.1205 17.6796 63.4334 17.7867 63.6887 17.9839C63.9434 18.1811 64.1253 18.4574 64.2061 18.7693L65.9027 25.3317L67.3334 18.6292C67.393 18.3607 67.5421 18.1203 67.7557 17.9472C67.9699 17.7742 68.2357 17.6786 68.5108 17.6761C68.6696 17.6749 68.827 17.7052 68.9737 17.7654C69.1205 17.8256 69.2544 17.9144 69.3665 18.0266C69.4787 18.1388 69.5673 18.2722 69.6277 18.4191C69.6877 18.5659 69.7182 18.7233 69.7168 18.882C69.7154 18.9712 69.7071 19.0601 69.6924 19.1481L67.9385 26.9156C67.837 27.3583 67.5896 27.7542 67.2361 28.0397C66.883 28.3253 66.4441 28.484 65.9894 28.4904C65.5353 28.4969 65.0922 28.3508 64.7308 28.0755C64.3694 27.8002 64.111 27.4116 63.9965 26.9719L62.6779 21.7696L61.3602 26.9719C61.2499 27.4092 60.9951 27.7965 60.6374 28.0711C60.2797 28.3457 59.8394 28.4915 59.3885 28.485C58.9376 28.4785 58.5019 28.32 58.1525 28.0352C57.8027 27.7504 57.5594 27.3559 57.4616 26.9156L55.7257 19.2593C55.7091 19.1663 55.6999 19.0721 55.6976 18.9777C55.6957 18.8073 55.7276 18.6382 55.7917 18.4802C55.8559 18.3222 55.9505 18.1785 56.0705 18.0574C56.1905 17.9362 56.3331 17.8402 56.4905 17.7746C56.6479 17.709 56.8168 17.6753 56.9871 17.6754Z"
                      fill="black"
                    />
                    <path
                      d="M69.8362 26.8734L72.5006 19.1615C72.653 18.727 72.9363 18.3506 73.312 18.0843C73.6877 17.818 74.1363 17.6749 74.597 17.6749C75.0571 17.6749 75.5062 17.818 75.8819 18.0843C76.2571 18.3506 76.5405 18.727 76.6928 19.1615L79.3291 26.7896C79.3734 26.9261 79.397 27.0685 79.3997 27.212C79.3983 27.5161 79.289 27.8099 79.091 28.0406C78.893 28.2714 78.6193 28.4241 78.3188 28.4714C78.0183 28.5186 77.711 28.4574 77.4516 28.2986C77.1922 28.1398 76.9979 27.8939 76.9033 27.6048L76.4408 26.1328H72.6266L72.1642 27.6329C72.1162 27.7879 72.0382 27.9318 71.9339 28.0562C71.83 28.1807 71.7022 28.2832 71.5582 28.3578C71.4137 28.4324 71.2563 28.4777 71.0948 28.4909C70.9333 28.5041 70.7703 28.4851 70.6162 28.4349C70.462 28.3847 70.3189 28.3044 70.1962 28.1985C70.0734 28.0927 69.9723 27.9635 69.8999 27.8183C69.8274 27.6732 69.7845 27.5152 69.7734 27.3533C69.7628 27.1914 69.784 27.029 69.8362 26.8755V26.8734ZM75.7822 24.0575L75.1125 21.9097C74.9011 21.2304 74.7737 20.739 74.6196 20.1294H74.4516C74.2993 20.7383 74.1733 21.2304 73.9586 21.9097L73.2857 24.0554L75.7822 24.0575Z"
                      fill="black"
                    />
                    <path
                      d="M80.4365 18.9792C80.4365 18.2048 80.8593 17.7873 81.6287 17.7873H84.7694C87.1953 17.7873 88.8919 19.1052 88.8919 21.4479C88.8919 23.7196 87.2659 25.1353 84.7556 25.1353H83.1296V27.1409C83.1213 27.4926 82.9759 27.8272 82.7244 28.0732C82.4728 28.3191 82.135 28.4568 81.7833 28.4568C81.4311 28.4568 81.0933 28.3191 80.8418 28.0732C80.5902 27.8272 80.4448 27.4926 80.4365 27.1409V18.9792ZM84.379 23.0734C85.459 23.0734 86.2436 22.651 86.2436 21.4895C86.2436 20.328 85.471 19.8521 84.391 19.8521H83.1296V23.0769L84.379 23.0734Z"
                      fill="black"
                    />
                  </svg>
                </Tween>
              </div>
              <div className={Styles.col}>
                <Tween
                  to={{
                    autoAlpha: 1,
                    duration: 0.9,
                    ease: `power2`,
                    delay: 0.1,
                    stagger: 0.2,
                    y: 0,
                  }}
                >
                  <p>
                    When using a DEX, the first thing that a user notices is
                    that there is a native token needed for gas fees (Network
                    fees). On VinuSwap, there are ZERO gas fees because of the
                    native network&apos;s properties. This baseline advantage
                    against DEXs on other popular blockchains cannot be
                    replicated or overcome, without the latter changing their
                    fundamental properties. Unless such radical changes are
                    made, VinuSwap stands tall above the competition.
                  </p>
                </Tween>
              </div>
            </div>

            <div className={Styles.photoWrap}>
              <img src={VinuSwapMockup} alt="" />
            </div>

            <div className={Styles.divider} />
          </Container>
        </section>
        <section className={Styles.MerchSection}>
          <Container>
            <div className={Styles.headingWrap}>
              <div className={Styles.col}>
                <Tween
                  to={{
                    autoAlpha: 1,
                    duration: 0.9,
                    ease: `power2`,
                    delay: 0.1,
                    stagger: 0.2,
                    y: 0,
                  }}
                >
                  <h1>Designer Clothing Line</h1>
                </Tween>
              </div>
              <div className={Styles.col}>
                <Tween
                  to={{
                    autoAlpha: 1,
                    duration: 0.9,
                    ease: `power2`,
                    delay: 0.1,
                    stagger: 0.2,
                    y: 0,
                  }}
                >
                  <p>
                    We are not just a cryptocurrency project. We are also a
                    lifestyle brand associated with, and promoting the ideas of,
                    eco-friendliness. Our designer and general merchandise are
                    ethically sourced and produced, in line with our commitment
                    to being an ethical and non-exploitative project. A portion
                    of proceeds will be donated (quarterly) to environmental
                    organizations. The rest are channeled back into the VINU DAO
                    reserves, for the continued development of the project.
                  </p>
                  {/* <div className={Styles.btnWrap}>
                    <Button theme="border" text="Visit our store" />
                  </div> */}
                </Tween>
              </div>
            </div>
            <Swiper
              modules={[Autoplay]}
              spaceBetween={42}
              slidesPerView={1}
              className={Styles.imagesWrap}
              loop={true}
              centeredSlides={true}
              speed={6500}
              autoplay={{
                delay: undefined,
                disableOnInteraction: false,
              }}
              breakpoints={{
                1110: {
                  slidesPerView: 4,
                },
                830: {
                  slidesPerView: 2,
                },
              }}
            >
              <SwiperSlide className={Styles.swiperSlide}>
                <div className={Styles.imageHolder}>
                  <img src={Image1} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={Styles.swiperSlide}>
                <div className={Styles.imageHolder}>
                  <img src={Image2} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={Styles.swiperSlide}>
                <div className={Styles.imageHolder}>
                  <img src={Image3} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={Styles.swiperSlide}>
                <div className={Styles.imageHolder}>
                  <img src={Image4} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide className={Styles.swiperSlide}>
                <div className={Styles.imageHolder}>
                  <img src={Image5} alt="" />
                </div>
              </SwiperSlide>
            </Swiper>
          </Container>
        </section>
      </ScrollTrigger>
    </div>
  );
};

export default Products;
