import Helmet from 'react-helmet';
import MainLayout from '@/layouts/MainLayout';
import Hero from '@/sections/Hero';
import Features from '@/sections/Features';
import Products from '@/sections/Products';

const Homepage = () => {
  return (
    <>
      <Helmet title="Vinu Foundation" defer={false}>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <MainLayout>
        <Hero />
        <Features />
        <Products />
      </MainLayout>
    </>
  );
};

export default Homepage;
