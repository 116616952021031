import React from 'react';
import Footer from '@/components/Footer';

interface MainLayoutProps {
  children?: React.ReactNode;
}

const MainLayout: React.FunctionComponent<MainLayoutProps> = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default MainLayout;
