// extracted by mini-css-extract-plugin
export var MerchSection = "style-module--MerchSection--KzOMV";
export var btnWrap = "style-module--btnWrap--XHUZu";
export var col = "style-module--col--Q30Nc";
export var divider = "style-module--divider--ExTIZ";
export var headingWrap = "style-module--headingWrap--Xjlv2";
export var imageHolder = "style-module--imageHolder--yCYwU";
export var imagesWrap = "style-module--imagesWrap--IDlOD";
export var photoWrap = "style-module--photoWrap--F9j2U";
export var productWrap = "style-module--productWrap--LH0ve";
export var swiperSlide = "style-module--swiperSlide--vAQWC";
export var vinuSwapSection = "style-module--vinuSwapSection--zQ2oN";