import React from 'react';
import Container from '@/components/Container';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import { Tween, ScrollTrigger } from 'react-gsap';
// @ts-ignore
import * as Styles from './style.module.scss';
// @ts-ignore
import MissionImage from '@/static/mission.png';
// @ts-ignore
import VisionImage from '@/static/vision.png';
// @ts-ignore
import TransparencyImage from '@/static/transparency.png';

const Features: React.FunctionComponent = () => {
  const featuresList = {
    sections: [
      {
        title: `Mission`,
        paragraph: `The mission of VINU Foundation is widening and equalizing access to environmentally-friendly and fee-less blockchain technology in the form of DAG (Directed Acyclic Graph) across the world.`,
        image: <img src={MissionImage} />,
        backgroundColor: `#fff`,
        theme: `light`,
      },
      {
        title: `Vision`,
        paragraph: `A world where the everyone can benefit from the revolutionary potential of environmentally-friendly blockchain technology without the need for middlemen gradually leeching fees from users.`,
        image: <img src={VisionImage} />,
        backgroundColor: `#000`,
        theme: `dark`,
      },
      {
        title: `Transparency`,
        paragraph: `We are built on the principles of transparency and accountability. Community members and investors are informed of key decisions about the VINU project, and invited to examine our supply and transactions on the blockchain. No shady business.`,
        image: <img src={TransparencyImage} />,
        backgroundColor: `#FBFBFB`,
        theme: `light`,
      },
    ],
  };

  const sectionsBlock = featuresList.sections;

  return (
    <ScrollTrigger start="top 65%">
      <>
        {sectionsBlock.map((item, i) => {
          return (
            <div
              key={uuidv4()}
              style={{ backgroundColor: item.backgroundColor }}
            >
              <Container>
                <div
                  className={cn(Styles.row, {
                    [`${Styles.evenFeatureRow}`]: i % 2 !== 0,
                  })}
                >
                  <div className={Styles.col}>
                    <Tween
                      to={{
                        autoAlpha: 1,
                        duration: 0.9,
                        ease: `power2`,
                        delay: 0.1,
                        stagger: 0.2,
                        y: 0,
                      }}
                    >
                      <h2
                        className={cn(Styles.title, {
                          [`${Styles.darkTitle}`]: item.theme === `dark`,
                          [`${Styles.reverseTitle}`]: i % 2 !== 0,
                        })}
                      >
                        {item.title}
                      </h2>
                      <p
                        className={cn(Styles.paragraph, {
                          [`${Styles.darkParagraph}`]: item.theme === `dark`,
                          [`${Styles.reverseParagraph}`]: i % 2 !== 0,
                        })}
                      >
                        {item.paragraph}
                      </p>
                    </Tween>
                  </div>
                  <div className={Styles.colImage}>
                    <Tween
                      to={{
                        autoAlpha: 1,
                        duration: 0.9,
                        ease: `power2`,
                        delay: 0.1,
                        stagger: 0.2,
                        y: 0,
                        rotation: 0,
                      }}
                    >
                      <div
                        className={cn(Styles.imgWrap, {
                          [`${Styles.imageLeft}`]: i % 2 !== 0,
                        })}
                      >
                        {item.image}
                      </div>
                    </Tween>
                  </div>
                </div>
              </Container>
            </div>
          );
        })}
      </>
    </ScrollTrigger>
  );
};

export default Features;
