// extracted by mini-css-extract-plugin
export var col = "style-module--col--41HrI";
export var colImage = "style-module--colImage--xT1kH";
export var darkParagraph = "style-module--darkParagraph--vpca0";
export var darkTitle = "style-module--darkTitle--ktsLZ";
export var evenFeatureRow = "style-module--evenFeatureRow--KA1-L";
export var imageLeft = "style-module--imageLeft--sbsHr";
export var imgWrap = "style-module--imgWrap--p9w6A";
export var paragraph = "style-module--paragraph--s24nQ";
export var reverseParagraph = "style-module--reverseParagraph--JsxOh";
export var reverseTitle = "style-module--reverseTitle--X6RZe";
export var row = "style-module--row--XyrZP";
export var title = "style-module--title--D2366";