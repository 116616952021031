import React from 'react';
import Container from '@/components/Container';
import Button from '@/components/Button';
import { SvgBrandOf } from '@/components/SVG';
import Header from '@/components/Header';
import { Tween, ScrollTrigger } from 'react-gsap';
// @ts-ignore
import * as Styles from './style.module.scss';

const Hero: React.FunctionComponent = () => {
  return (
    <>
      <ScrollTrigger start="top 65%">
        <section className={Styles.HeroSection}>
          <Header />
          <Container wider>
            <Tween
              to={{
                autoAlpha: 1,
                duration: 0.9,
                ease: `power2`,
                delay: 0.1,
                stagger: 0.2,
                y: 0,
              }}
            >
              <div className={Styles.noticeWrap}>
                <div className={Styles.notice}>A foundation for good</div>
              </div>
              <h1 className={Styles.heading}>
                The Driving Force behind Vita Inu and eliminating fees in the
                financial system
              </h1>
              <p className={Styles.paragraph}>
                The VINU Foundation supports VINU in its goals to widen and
                equalize access to environmentally-friendly blockchain
                technology.
              </p>
              <div className={Styles.btnWrap}>
                <Button
                  theme="primary"
                  text="VINU main site"
                  type="link"
                  href="https://vinu.org"
                  target="_blank"
                />
              </div>
              <div className={Styles.brandOf}>
                <SvgBrandOf width={140} />
              </div>
            </Tween>
          </Container>
        </section>
      </ScrollTrigger>
    </>
  );
};

export default Hero;
